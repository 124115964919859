'use strict';

export default function () {

  const steps = document.querySelectorAll('.step-container')
  if (steps.length > 0) {

    const containerStep = document.querySelector('.steps-itens')
    const btnsteps = document.querySelector('.button-form-steps')
    const wpcf7Elm = document.querySelectorAll('.wpcf7');
    steps[0].classList.add('active')
    btnsteps.setAttribute('disabled', 'disabled')
    let countStep = 1;
    steps.forEach((item, index) => { //Bullet Numbers Steps

      let step = document.createElement('span')
      step.classList.add('step--item')
      if (item.classList.contains('active')) {
        step.classList.add('form-complete')
      }
      step.innerText = countStep++;
      containerStep.appendChild(step)

    })

    let stepsBtn = 0;

    setTimeout(() => {
      const bulletStep = document.querySelectorAll('.step--item')

      bulletStep.forEach((item, index) => {
        item.addEventListener('click', () => {
          if (item.classList.contains('form-complete') && index < stepsBtn) {
            stepsBtn = index
            steps.forEach(item => item.classList.remove('active'))

            for (let i = index; i < steps.length; i++) {
              bulletStep[i].classList.remove('form-complete')
              bulletStep[i].style.cursor = "auto"
            }

            if (index != 0) {
              for (let x = index - 1; x >= 0; x--) {
                bulletStep[x].style.cursor = "pointer"
              }
            }

            steps[index].classList.add('active')
            item.classList.add('form-complete')
            item.style.cursor = "auto"
            btnsteps.innerText = "Next"
            validacao = steps[stepsBtn].querySelectorAll('.wpcf7-form-control-wrap')
            valida_type(validacao)
            if (steps[stepsBtn].dataset.steps == steps.length) {
              btnsteps.removeAttribute('disabled')
            }

          }
        })
      })

      btnsteps.addEventListener('click', (e) => {
        steps.forEach(item => item.classList.remove('active'))

        if (stepsBtn < steps.length - 1) {
          e.preventDefault();

          for (let x = stepsBtn; x >= 0; x--) {
            bulletStep[x].style.cursor = "pointer"
          }

          stepsBtn++
        }
        if (stepsBtn >= steps.length - 1) {
          btnsteps.innerText = "Submit"
        }

        liberabtn = 0
        steps[stepsBtn].classList.add('active')
        bulletStep[stepsBtn].classList.add('form-complete')
        validacao = steps[stepsBtn].querySelectorAll('.wpcf7-form-control-wrap')
        valida_type(validacao)
        if (steps[stepsBtn].dataset.steps == steps.length) {
          btnsteps.removeAttribute('disabled')
        }

      })

    }, 2000)

    //let validacao = steps[stepsBtn].querySelectorAll('input')

    let validacao = steps[stepsBtn].querySelectorAll('.wpcf7-form-control-wrap')
    let liberabtn = 0

    steps.forEach((step) => {
      let container = step.querySelectorAll('.wpcf7-form-control-wrap')
      container.forEach((type) => {
        let valores = type.querySelector('input') ? type.querySelector('input') : type.querySelector('textarea')
        type.dataset.valida = valores.type
      })
    })

    function libera_btn(liberabtn, type) {
      if (liberabtn >= type.length) {
        btnsteps.removeAttribute('disabled')
      } else {
        btnsteps.setAttribute('disabled', 'disabled')
      }
    }

    /*function valida_type(type) {
      type.forEach((item) => {
        let input = item.querySelectorAll('input') ? item.querySelectorAll('input') : item.querySelectorAll('textarea')
        if (item.dataset.valida == 'checkbox' || item.dataset.valida == 'radio') {
          input.forEach((val) => {
            val.addEventListener('change', () => {
              if (val.checked) {
                liberabtn++
                steps[stepsBtn].dataset.steps = liberabtn
              } else {
                if (liberabtn <= 0) {
                  liberabtn = 0
                }
                liberabtn--
              }
              if (liberabtn >= type.length) {
                btnsteps.removeAttribute('disabled')
              } else {
                btnsteps.setAttribute('disabled', 'disabled')
              }
            })
          })
        } else {
          input.forEach((text) => {
            text.addEventListener('change', () => {
              if (text.value.length > 0) {
                liberabtn++
                steps[stepsBtn].dataset.steps = liberabtn
              } else {
                if (liberabtn <= 0) {
                  liberabtn = 0
                }
                liberabtn--
              }
              if (liberabtn >= type.length) {
                btnsteps.removeAttribute('disabled')
              } else {
                btnsteps.setAttribute('disabled', 'disabled')
              }
            })
          })
        }
      })
    }*/

    function valida_type(type) {
      type.forEach((item) => {
        let input = item.querySelectorAll(':is(input, textarea)')
        input.forEach((val) => {
          val.addEventListener('input', () => {
            if (val.type == 'checkbox' || val.type == "radio") {
              if (val.checked) {
                liberabtn++
                steps[stepsBtn].dataset.steps = liberabtn
              } else {
                if (liberabtn <= 0) {
                  liberabtn = 0
                }
                liberabtn--
              }
            }

            if (val.type == "text" || val.type == "email" || val.type == "tel" || val.type == "textarea" || val.type == "number") {
              if (val.value.length > 0) {
                liberabtn++
                steps[stepsBtn].dataset.steps = liberabtn
              } else {
                if (liberabtn <= 0) {
                  liberabtn = 0
                }
                liberabtn--
              }
            }

            if (liberabtn >= type.length) {
              btnsteps.removeAttribute('disabled')
            } else {
              btnsteps.setAttribute('disabled', 'disabled')
            }


          }, '')
        })
      })
    }


    wpcf7Elm.forEach((form) => {
      let stepForm = form.querySelectorAll('.step-container')
      let bulletStep = document.querySelectorAll('.step--item')
      let btnsteps = document.querySelector('.button-form-steps')
      form.addEventListener('wpcf7mailsent', function (event) {
        if (stepForm.length > 1) {
          stepForm.forEach(item => item.classList.remove('active'))
          stepForm[0].classList.add('active')
          bulletStep.forEach(item => item.classList.remove('form-complete'))
          btnsteps.setAttribute('disabled', 'disabled')
          bulletStep[0].classList.add('form-complete')
          libera_btn = 0
        }
      }, false);
    })


    valida_type(validacao)
  }

}
